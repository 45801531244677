import React, { useEffect, useState } from "react";

async function navigateToQuery({ type, query }) {
  if (type === "id") {
    window.location.assign(`/search/details?id=${query}`);
  } else if (type === "report") {
    window.location.assign(`/search/details?report=${query}`);
  } else if (type === "query") {
    window.location.assign(`/search?q=${query}`);
  }
}

function BreadcrumbSearch(props) {
  const [query, setQuery] = useState("");

  const params = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );

  const defineReportType = () => {
    if (query.includes('item-')) {
      return "id";
    } else if (!isNaN(query)) {
      return "report";
    } else {
      return "query";
    }
  };

  useEffect(() => {
    setQuery(params.q || params.id || params.report);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    navigateToQuery({
      type: defineReportType(),
      query,
    });
  };

  return (
    <>
      <section className="breadcrumb_area">
        {/* <img className="p_absolute bl_left" src="/assets/img/v.svg" alt="" />
        <img
          className="p_absolute bl_right"
          src="/assets/img/home_one/b_leaf.svg"
          alt=""
        />
        <img
          className="p_absolute star"
          src="/assets/img/home_one/banner_bg.png"
          alt=""
        />
        <img
          className="p_absolute wave_shap_one"
          src="/assets/img/blog-classic/shap_01.png"
          alt=""
        />
        <img
          className="p_absolute wave_shap_two"
          src="/assets/img/blog-classic/shap_02.png"
          alt=""
        /> */}
        <div className="container custom_container">
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            autoComplete="off"
            className="banner_search_form banner_search_form_two"
          >
            <div className="input-group d-flex flex-row">
              <input
                type="search"
                className="form-control"
                placeholder=""
                id="query-input"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <button type="submit">
                <i className="icon_search"></i>
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default BreadcrumbSearch;
