import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import NavbarSearch from "../components/navbar/NavbarSearch";
import NavbarMobileSearch from "../components/navbar/NavbarMobileSearch";
import FooterSearchLanding from "../components/footer/FooterSearchLanding";
import BreadcrumbSearch from "../components/breadcrumb/BreadcrumbSearch";

const LayoutSearch = () => {
  const [pageName, setPageName] = useState('');
  const [entityLayout, setEntityLayout] = useState({})

  useEffect(() => {
    const pageLoaded = new Event('view-loaded');
    window.dispatchEvent(pageLoaded);
  }, []);

  return (
    <>
      <div className="body_wrapper">

        <NavbarSearch pageName={pageName} />

        <div className="click_capture"></div>

        <NavbarMobileSearch />

        <BreadcrumbSearch pageName={pageName} entityLayout={entityLayout} />

        <section className="doc_documentation_area d-flex align-items-stretch" id="sticky_doc">
          <div className="overlay_bg"></div>
          <div className="container-fluid d-flex align-items-stretch w-100 ml-0 pl-0">
            <Outlet context={{ setPageName, setEntityLayout }} />
          </div>
        </section>

        <FooterSearchLanding />

      </div >
    </>
  );
};

export default LayoutSearch;