import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import SidebarSearch from "../../components/sidebar/SidebarSearch";
import { ItemService } from "../../services/index";
import { FormControl, Select, MenuItem, Pagination } from "@mui/material";

async function getItems(params) {
  const entityService = new ItemService();
  const entityResponse = await entityService.getByParameters(params);

  return entityResponse?.result ?? [];
}

function dataLoaded() {
  var event = new Event("page-loaded");
  window.dispatchEvent(event);
}

function SearchResults() {
  const { setPageName } = useOutletContext();
  const [entities, setEntities] = useState([]);
  const [sortBy, setSortBy] = React.useState("most-relevant");
  const [pagination, setPagination] = useState({
    pageSize: 24,
    page: 1,
    totalItems: 0,
    totalPages: 0,
  });
  const params = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );

  const handleChange = (event) => {
    setSortBy(event.target.value);
  };

  const getItemsByQuery = async () => {
    const result = await getItems({
      queryselector: "text",
      search: params.q,
      pageSize: pagination.pageSize,
      page: pagination.page,
    });

    setItems(result ?? {});
    dataLoaded();
  };

  const getAllItems = async () => {
    const result = await getItems({
      queryselector: "all",
      search: params.q,
      pageSize: pagination.pageSize,
      page: pagination.page,
    });

    setItems(result ?? {});
    dataLoaded();
  };

  const setItems = (result) => {
    setEntities(result?.items ?? []);

    handlePaginationDataChange("totalItems", result?.totalItems ?? 0);
    handlePaginationDataChange("totalPages", result?.totalPages ?? 0);
  };

  const handlePaginationDataChange = (fieldName, data) => {
    setPagination((prevUserData) => ({
      ...prevUserData,
      [fieldName]: data,
    }));
  };

  const handleChangePage = (event, value) => {
    handlePaginationDataChange("page", value ?? 1);
  };

  const search = async () => {
    if (!params.q || params.q === "all") {
      await getAllItems();
    } else {
      await getItemsByQuery();
    }
  };

  /**
   * On page loaded => Set page name
   */
  useEffect(() => {
    console.log("changed page");
    search();
  }, [pagination.page]);

  /**
   * On page loaded => Get results
   */
  useEffect(() => {
    setPageName("Results page");
    search();
  }, []);

  return (
    <>
      <section className="d-flex w-100">
        {/* Left sidebar */}
        <SidebarSearch />

        {/* Content */}
        <section className="col-12 col-md-10 mx-auto">
          <section className="d-flex flex-wrap mt-3">
            <header className="col-12 d-flex flex-column flex-md-row justify-content-between">
              <section className="d-flex flex-grow-1">
                <p>Sort by</p>
                <FormControl
                  fullWidth
                  variant="standard"
                  className="col-8 col-md-2 mx-2"
                >
                  <Select
                    labelId="sort-by-label"
                    id="sort-by"
                    value={sortBy}
                    onChange={handleChange}
                  >
                    <MenuItem value="most-relevant">Most relevant</MenuItem>
                  </Select>
                </FormControl>
              </section>
              <section>
                <p>
                  {pagination?.pageSize} of {pagination?.totalItems} items
                </p>
              </section>
            </header>
          </section>

          <section className="d-flex flex-wrap mt-3">
            {entities?.map((entity) => (
              <section
                className="col-4 col-md-4 col-lg-3 col-xl-2"
                key={entity.id}
              >
                <a
                  href={`/search/details?id=${entity.id}`}
                  target="_blank"
                  className="bold"
                >
                  <article className="card border-0">
                    <small className="text-muted text-truncate">
                      Report: {entity?.metadata?.report}
                    </small>
                    <section className="bg-white">
                      <img
                        src={
                          entity?.media?.[0]?.url ||
                          "/assets/img/preview-unavailable.jpg"
                        }
                        className={`card-img-top object-fit-contain search-image-result ${
                          entity?.media?.length > 0 ? "" : "opacity-07"
                        }`}
                        alt={entity?.name}
                        height="300"
                      />
                    </section>

                    <section className="card-body px-0 pt-2">
                      <h6 className="mb-0 text-truncate font-weight-normal">
                        {entity?.name} {entity?.properties?.cut?.name} {entity?.properties?.cut?.style}
                      </h6>
                      <p className="mb-0">
                        <span className="badge badge-light px-2">
                          {entity?.properties?.size?.trade_measure}
                        </span>

                        <span className="badge badge-light px-2">
                          {entity?.properties?.weight?.trade_weight}
                        </span>
                      </p>
                    </section>
                  </article>
                </a>
              </section>
            ))}
          </section>

          <section className="d-flex justify-content-center mb-4">
            <Pagination
              count={pagination.totalPages ?? 1}
              onChange={handleChangePage}
            />
          </section>
        </section>
      </section>
    </>
  );
}

export default SearchResults;
